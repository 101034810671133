import { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import { BrowserRouter } from "react-router-dom";
import route from "./router";
import dva from "./utils/dva";
import "antd/dist/antd.css";
import { getModuleInfo, getUserInfo, getMapData } from "./utils/hybrid";
import request from "./utils/request";
import * as serviceApi from "./utils/service";
import { localStorage } from "js-storage";
import VConsole from 'vconsole';


import FastClick from 'fastclick'
import { ifStatement } from "@babel/types";
// 修复点击300ms延迟
if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', () => {
    FastClick.attach(document.body)
  }, false)
}
FastClick.prototype.focus = function(targetElement) {
  targetElement.focus();
};


// import styles from './App.module.less';

// dva 配置
const pagesModelsContext = require.context("./models", true, /\.js$/);

const componentsModelsContext = require.context("./", true, /model\.js$/);

const pageModels = pagesModelsContext.keys().map((item) => {
  const component = pagesModelsContext(item);
  return component.default || component;
});

const componentsModels = componentsModelsContext.keys().map((item) => {
  const component = componentsModelsContext(item);
  return component.default || component;
});

const models = [...pageModels, ...componentsModels];

const dvaApp = dva.createApp({
  initialState: {},
  models: models,
});

const store = dvaApp.getStore();

//解决module_id获取不到问题
const getMIdByMKey = async()=>{
  let res  = await  serviceApi.getMIdByMKey({
    menu_key:1
  })
  localStorage.set("moduleInfo", res);
} 
function App() {
  useEffect(() => {
    // 是否本地测试
    let isLocal = false;
    if (!isLocal) {
       const vConsole = new VConsole();
      /* 正式环境使用 */
      localStorage.set("userInfo", getUserInfo());
      localStorage.set("moduleInfo", getModuleInfo());
      // alert(getModuleInfo())
      if(!getModuleInfo()){
       getMIdByMKey();
      }
      /* 正式环境使用 */
    } else {
      // const vConsole = new VConsole();
      /* 本地环境测试使用   模拟用户登录以及用户权限 */
      localStorage.removeAll();
      if (!localStorage.get("userInfo")) {
        request({
          url: "/staffController/staffLogin",
          method: "POST",
          data: {
            is_app: 1,
            staff_account: "李盛光",
            staff_password: "2wsx3EDC",
          },
        }).then((res) => {
          if (res) {
            localStorage.set("userInfo", res);
          }
        });
        localStorage.set("userInfo", getUserInfo());
      }
      if (!localStorage.get("moduleInfo")) {
        localStorage.set("moduleInfo", getModuleInfo());
      
        localStorage.set("moduleInfo", {
          app_id: 33,
          role_id: 4,
          menu_id: 74,
          app_operation: "0,1",
          app_data: "0",
          app_department: "",
          create_time: "",
          staff_id: "",
          module_url: "",
        });
      }
      /*本地环境使用*/
    }
  }, []);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading... </div>}>
          {renderRoutes(route)}
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
